var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"coupon-list"},[_c('div',{staticClass:"header"},[_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$route.name)+" - ["+_vm._s(_vm.couponActivity.title)+"] "),_c('a-button',{attrs:{"type":"primary","shape":"round","icon":"plus"},on:{"click":_vm.onAdd}},[_vm._v(" 添加优惠券 ")]),_c('v-form-modal',{attrs:{"name":"优惠券","visible":_vm.visible,"isAdd":_vm.isAdd,"fields":_vm.fields,"model":_vm.model,"add":_vm.dispatch.couponsPost,"edit":_vm.dispatch.couponsPatch,"width":"900px","labelCol":{ span: 6 },"wrapperCol":{ span: 14 }},on:{"ok":_vm.onOk,"cancel":function($event){_vm.visible = false}}})],1),_c('a-divider'),_c('a-form',{staticClass:"form",attrs:{"form":_vm.form}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":"优惠券名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['title__icontains']),expression:"['title__icontains']"}],staticClass:"form-field",attrs:{"placeholder":"请输入优惠券名称"},on:{"blur":_vm.onFilterChange,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onFilterChange($event)}}})],1),_c('div',{staticClass:"actions"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onFilterChange}},[_vm._v(" 确认筛选 ")]),_c('a-button',{on:{"click":_vm.onFilterReset}},[_vm._v(" 清空条件 ")])],1)],1)],1),_c('div',{staticClass:"content"},[_c('a-table',{staticClass:"table",attrs:{"columns":_vm.columns,"data-source":_vm.list,"loading":_vm.loading,"pagination":{
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: function (total) { return ("总计 " + total + " 条"); },
        current: Number(_vm.query.page_number),
        pageSize: Number(_vm.query.page_size),
        total: Number(_vm.count)
      },"rowKey":function (record) { return record.coupon.id; },"size":"middle"},on:{"change":_vm.onTableChange},scopedSlots:_vm._u([{key:"active",fn:function(text, record){return [_c('a-switch',{attrs:{"checked":text},on:{"change":function($event){return _vm.onActiveChange(record)}}})]}},{key:"time",fn:function(text){return [_vm._v(" "+_vm._s(_vm._f("timeFormat")(text))+" ")]}},{key:"actions",fn:function(text, record){return [_c('a',{on:{"click":function($event){return _vm.onEdit(record)}}},[_vm._v("编辑")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{on:{"click":function($event){return _vm.onEditCount(record)}}},[_vm._v("增发")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('v-list-modal',{attrs:{"name":"同步记录","fields":_vm.syncFields,"get":function () { return _vm.dispatch.couponsGetCouponSyncList({
                coupon_id: record.coupon.id,
                page_size: 1000
              }); },"ok":function () { return _vm.onSync(record); },"okText":"立即同步"}},[_c('a',[_vm._v("同步")])]),_c('br'),(_vm.couponActivity.activity_type === 'private')?[_c('a',{on:{"click":function($event){return _vm.onShowCode(record)}}},[_vm._v("券码管理")]),_c('a-divider',{attrs:{"type":"vertical"}})]:_vm._e(),_c('a',{on:{"click":function($event){return _vm.onShowClaim(record)}}},[_vm._v("领取情况")])]}}])}),_c('v-form-modal',{attrs:{"name":"优惠券发放数量","visible":_vm.countVisible,"isAdd":true,"fields":_vm.countFields,"model":_vm.countModel,"add":_vm.dispatch.couponsPatchCount,"width":"400px","labelCol":{ span: 9 },"wrapperCol":{ span: 15 }},on:{"ok":_vm.onCountOk,"cancel":function($event){_vm.countVisible = false}}}),_c('a-modal',{attrs:{"title":("券码管理 - [" + (_vm.model.coupon.title) + "]"),"visible":_vm.codeVisible,"width":"1000px","destroyOnClose":""},on:{"ok":function($event){_vm.codeVisible = false},"cancel":function($event){_vm.codeVisible = false}}},[_c('CouponCodeList',{attrs:{"coupon":_vm.model,"couponActivity":_vm.couponActivity}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }