


























































































































import Vue from "vue";
import moment, { Moment } from "moment";
import _ from "loadsh";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import {
  CouponCategory,
  CouponType,
  CouponValidityDateType,
  CouponValidityGoodsType
} from "@/enum";
import { dispatch, ListQuery, Coupon, CouponActivity } from "@/store";
import CouponCodeList from "./CouponCodeList.vue";

export default Vue.extend({
  components: {
    CouponCodeList
  },
  data() {
    return {
      dispatch,
      loading: false,
      couponActivity: {} as CouponActivity,
      form: this.$form.createForm(this),
      list: [],
      query: {
        ordering: "-id",
        page_number: 1,
        page_size: 10
      },
      count: 0,
      visible: false,
      isAdd: false,
      model: {
        coupon: { type: "discount" },
        coupon_rule: { validity_date_type: "fixed", validity_goods_type: "all" }
      } as Coupon,
      countVisible: false,
      countModel: {} as Coupon,
      addCount: 0,
      codeVisible: false
    };
  },
  computed: {
    activityId(): number {
      return Number(this.$route.params.id);
    },
    fields(): any[] {
      const { category, type, discount } = this.model.coupon || {};
      const { validity_date_type, validity_goods_type } =
        this.model.coupon_rule || {};
      const fields: any[] = [
        {
          title: "优惠券名称",
          dataIndex: "coupon.title",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入优惠券名称",
              trigger: "blur"
            },
            {
              max: 40,
              message: "请输入少于 40 个字符",
              trigger: "blur"
            }
          ]
        },
        {
          title: "优惠券描述",
          dataIndex: "coupon.description",
          type: "textarea",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入优惠券描述",
              trigger: "blur"
            },
            {
              max: 400,
              message: "请输入少于 400 个字符",
              trigger: "blur"
            }
          ]
        },
        {
          title: "发放数量",
          dataIndex: "coupon.total_count",
          type: "number",
          min: 1,
          precision: 0,
          immutable: true,
          rules: [
            {
              required: true,
              message: "请输入发放总数"
            }
          ]
        },
        {
          title: "券码分类",
          dataIndex: "coupon.category",
          type: "radio",
          options: CouponCategory,
          immutable: true,
          rules: [
            {
              required: true,
              message: "请选择券码分类"
            }
          ],
          customRender: (text: string) => (CouponCategory as any)[text],
          change: (value: string, form: WrappedFormUtils) => {
            const type = value === "early_bird" ? "early_bird" : "save";
            const goods_type =
              value === "early_bird" ? "product" : validity_goods_type;
            this.model.coupon.category = value;
            this.model.coupon.type = type;
            this.model.coupon_rule.validity_goods_type = goods_type;
            form.setFieldsValue({
              "coupon.type": type,
              "coupon_rule.validity_goods_type": goods_type
            });
            this.model = { ...this.model };
          }
        },
        {
          title: "优惠券类型",
          dataIndex: "coupon.type",
          type: "radio",
          options: CouponType,
          rules: [
            {
              required: true,
              message: "请选择优惠券类型"
            }
          ],
          customRender: (
            type: string,
            {
              coupon: {
                amount_threshold,
                discount,
                save_amount,
                max_amount_limit
              }
            }: Coupon
          ) => {
            let text = "";
            if (amount_threshold) {
              text += "满 " + amount_threshold + " ";
            } else {
              text += "无门槛";
            }
            if (type === "discount") {
              text += "打 " + discount / 10 + " 折";
              if (max_amount_limit) {
                text += "，最多优惠 " + max_amount_limit;
              }
            } else if (type === "save") {
              text += "减 " + save_amount;
            } else if (type === "early_bird") {
              text = "必购码";
            }
            return text;
          },
          change: (value: string) => {
            this.model.coupon.type = value;
            this.model = { ...this.model };
          },
          hidden: true
        },
        {
          title: "满减门槛",
          dataIndex: "coupon.amount_threshold",
          type: "number",
          min: 1,
          precision: 0,
          rules: [
            {
              required: category === "special_offer",
              message: "请输入满减门槛"
            }
          ],
          extra: "有效的结算金额大于或等于多少时可以使用此优惠",
          change: (value: number) => {
            this.model.coupon.amount_threshold = value;
            this.model = { ...this.model };
          },
          hidden: category !== "special_offer"
        },
        {
          title: "优惠折扣",
          dataIndex: "coupon.discount",
          type: "number",
          min: 1,
          max: 99,
          precision: 0,
          rules: [
            {
              required: type === "discount",
              message: "请输入优惠折扣"
            },
            {
              validator: (rule: any, value: number, callback: Function) => {
                if (type === "discount" && value < 60) {
                  callback("折扣最低为 6折");
                  return;
                }
                callback();
              }
            }
          ],
          extra: discount && `当前设置折扣为 ${discount / 10}折`,
          change: (value: number) => {
            this.model.coupon.discount = value;
            this.model = { ...this.model };
          },
          hidden: type !== "discount"
        },
        {
          title: "最大优惠金额",
          dataIndex: "coupon.max_amount_limit",
          type: "number",
          min: 0,
          precision: 0,
          rules: [
            {
              required: type === "discount",
              message: "请输入最大优惠金额"
            },
            {
              validator: (rule: any, value: number, callback: Function) => {
                if (
                  type === "discount" &&
                  value >= this.model.coupon.amount_threshold
                ) {
                  callback("最大优惠金额必须小于满减门槛");
                  return;
                }
                callback();
              }
            }
          ],
          extra: "最多满减多少元，避免⾼额订单扣减过多",
          hidden: type !== "discount"
        },
        {
          title: "优惠金额",
          dataIndex: "coupon.save_amount",
          type: "number",
          min: 1,
          precision: 0,
          rules: [
            {
              required: type === "save",
              message: "请输入优惠金额"
            },
            {
              validator: (rule: any, value: number, callback: Function) => {
                if (
                  type === "save" &&
                  value >= this.model.coupon.amount_threshold
                ) {
                  callback("优惠金额必须小于满减门槛");
                  return;
                }
                callback();
              }
            }
          ],
          hidden: type !== "save"
        },
        {
          title: "生效期限",
          dataIndex: "coupon_rule.validity_date_type",
          type: "radio",
          options: CouponValidityDateType,
          rules: [
            {
              required: true,
              message: "请选择生效期限"
            }
          ],
          customRender: (
            type: string,
            { coupon_rule: { start_at, end_at, validity_days } }: Coupon
          ) => {
            if (type === "fixed") {
              return (
                moment(start_at).format("YYYY-MM-DD HH:mm:ss") +
                moment(end_at).format(" 至 YYYY-MM-DD HH:mm:ss")
              );
            } else {
              return "领取后 " + validity_days + " 天内有效";
            }
          },
          change: (value: string) => {
            this.model.coupon_rule.validity_date_type = value;
            this.model = { ...this.model };
          }
        },
        {
          title: "生效开始时间",
          dataIndex: "coupon_rule.start_at",
          type: "datetime",
          rules: [
            {
              required: validity_date_type === "fixed",
              message: "请选择开始时间"
            }
          ],
          scopedSlots: { customRender: "time" },
          change: (m: Moment, value: string) => {
            this.model.coupon_rule.start_at = value;
            this.model = { ...this.model };
          },
          hidden: validity_date_type === "since_today"
        },
        {
          title: "生效结束时间",
          dataIndex: "coupon_rule.end_at",
          type: "datetime",
          rules: [
            {
              required: validity_date_type === "fixed",
              message: "请选择结束时间"
            },
            {
              validator: (rule: any, value: number, callback: Function) => {
                if (
                  moment(value).isBefore(
                    moment(this.model.coupon_rule.start_at)
                  )
                ) {
                  callback("结束时间大于开始时间");
                  return;
                }
                callback();
              }
            }
          ],
          scopedSlots: { customRender: "time" },
          hidden: validity_date_type === "since_today"
        },
        {
          title: "有效天数",
          dataIndex: "coupon_rule.validity_days",
          type: "number",
          min: 1,
          precision: 0,
          rules: [
            {
              required: validity_date_type === "since_today",
              message: "请输入有效天数"
            }
          ],
          extra: "优惠券在领取后的多少天内有效，超时过期",
          hidden: validity_date_type === "fixed"
        },
        {
          title: "单人限领",
          dataIndex: "coupon_rule.per_claim_number",
          type: "number",
          min: 1,
          precision: 0,
          rules: [
            {
              required: true,
              message: "请输入有效数量"
            }
          ],
          extra: "每人最多只能领取多少次优惠券"
        },
        {
          title: "适用范围",
          dataIndex: "coupon_rule.validity_goods_type",
          type: "radio",
          options: CouponValidityGoodsType,
          rules: [
            {
              required: true,
              message: "请选择适用范围"
            }
          ],
          customRender: (text: string) =>
            (CouponValidityGoodsType as any)[text],
          change: (value: string) => {
            this.model.coupon_rule.validity_goods_type = value;
            this.model = { ...this.model };
          },
          hidden: category === "early_bird"
        },
        {
          title: "适用商品",
          dataIndex: "coupon_rule.validity_goods_type_ids",
          type: "products",
          hidden: validity_goods_type === "all"
        },
        {
          title: "是否启用",
          dataIndex: "coupon.is_active",
          type: "switch",
          scopedSlots: { customRender: "active" }
        }
      ];
      return fields;
    },
    columns() {
      let columns: any = this.fields.slice();
      columns = columns.filter((column: any) =>
        [
          "coupon.category",
          "coupon.title",
          "coupon.total_count",
          "coupon.type",
          "coupon_rule.validity_date_type",
          "coupon_rule.validity_goods_type",
          "coupon.is_active"
        ].includes(column.dataIndex)
      );
      columns = columns.concat([
        {
          title: "创建时间",
          dataIndex: "coupon.create_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "更新时间",
          dataIndex: "coupon.update_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "操作",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          width: "200px",
          align: "center"
        }
      ]);
      columns.unshift({
        title: "优惠券 ID",
        dataIndex: "coupon.id"
      });
      return columns;
    },
    countFields(): any[] {
      const totalCount = this.model.coupon.total_count;
      return [
        {
          title: "新增发放数量",
          dataIndex: "count",
          type: "number",
          min: 1,
          max: 99,
          precision: 0,
          rules: [
            {
              required: true,
              message: "请输入新增发放数量"
            }
          ],
          help: this.addCount ? `当前发放总数为：${totalCount}` : undefined,
          extra: this.addCount
            ? `更新后发放总数为：${totalCount + this.addCount}`
            : `当前发放总数为：${totalCount}`,
          change: (value: number) => {
            this.addCount = value;
            this.countModel.coupon.total_count = totalCount + value;
          }
        }
      ];
    },
    syncFields(): any[] {
      return [
        {
          title: "同步管理员 ID",
          dataIndex: "updater"
        },
        {
          title: "同步时间",
          dataIndex: "update_time",
          customRender: (time: string) =>
            time && moment(time).format("YYYY-MM-DD HH:mm:ss")
        }
      ];
    }
  },
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .couponsGetList({
          ids: this.couponActivity.coupon_ids,
          ...query
        })
        .then(res => {
          this.list = res.results;
          this.query = {
            ...query,
            page_number: res.page_number,
            page_size: res.page_size
          };
          this.count = res.count;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onFilterChange() {
      const filter = this.form.getFieldsValue();
      this.getList({
        ...this.query,
        ...filter,
        page_number: 1
      });
    },
    onFilterReset() {
      this.form.resetFields();
      this.onFilterChange();
    },
    onAdd() {
      this.visible = true;
      this.isAdd = true;
      this.model = {
        coupon: {
          category: "special_offer",
          type: "save",
          is_active: true
        },
        coupon_rule: {
          validity_date_type: "fixed",
          validity_goods_type: "all",
          validity_goods_type_ids: [] as number[]
        }
      } as Coupon;
    },
    onEdit(coupon: Coupon) {
      this.visible = true;
      this.isAdd = false;
      this.model = _.cloneDeep(coupon);
    },
    onOk(coupon: Coupon) {
      this.visible = false;
      this.onFilterChange();
      if (this.isAdd) {
        this.couponActivity.coupon_ids = [
          ...(this.couponActivity.coupon_ids || []),
          coupon.coupon.id
        ];
        dispatch.couponsPatchActivity(this.couponActivity).then(() => {
          this.onFilterChange();
        });
      }
    },
    onSync(coupon: Coupon) {
      return new Promise(resole => {
        this.$confirm({
          title: "确认将此优惠券信息进行同步？",
          content: "同步后用户已经领取的优惠券信息将与此优惠券信息一致",
          centered: true,
          onOk: () => {
            dispatch.couponsSyncUserCoupon(coupon.coupon.id).then(() => {
              this.$message.success("同步成功");
              resole(true);
            });
          }
        });
      });
    },
    onEditCount(coupon: Coupon) {
      this.countVisible = true;
      this.addCount = 0;
      this.model = _.cloneDeep(coupon);
      this.countModel = _.cloneDeep(coupon);
    },
    onCountOk() {
      this.countVisible = false;
      this.addCount = 0;
      this.onFilterChange();
    },
    onTableChange(page: any, filters: any, sorter: any) {
      let ordering =
        sorter.order === "ascend" ? "-" + (sorter.columnKey || "") : "";
      ordering = sorter.order === "descend" ? sorter.columnKey || "" : ordering;
      this.getList({
        ...this.query,
        page_number: page.current,
        page_size: page.pageSize,
        ordering: ordering || this.query.ordering
      });
    },
    onActiveChange(coupon: Coupon) {
      coupon.coupon.is_active = !coupon.coupon.is_active;
      dispatch.couponsPatch(coupon).then(() => {
        this.getList(this.query);
      });
    },
    onShowCode(coupon: Coupon) {
      this.model = _.cloneDeep(coupon);
      this.codeVisible = true;
    },
    onShowClaim(coupon: Coupon) {
      dispatch
        .couponsGetClaimInfo(coupon.coupon.id)
        .then(res => {
          this.$info({
            title: `优惠券 - ${coupon.coupon.title}`,
            content: (h: Function) =>
              h("div", [
                h("div", "已发放数量：" + res.claimed),
                h("br"),
                h("div", "此数量包含:"),
                h("div", "1. 用户直接领取的优惠券数量"),
                h("div", "2. 已经批量生成的券码总数")
              ]),
            centered: true
          });
        })
        .catch(() => {
          this.$message.destroy();
          this.$message.info("暂无领取信息");
        });
    }
  },
  mounted() {
    if (this.activityId) {
      this.loading = true;
      dispatch
        .couponsGetActivity(this.activityId)
        .then((couponActivity: CouponActivity) => {
          this.couponActivity = couponActivity;
          this.getList(this.query);
        });
    }
  }
});
