








































































































import Vue, { PropType } from "vue";
import { CouponCodeStatus, CouponCodeSendFlag } from "@/enum";
import {
  dispatch,
  ListQuery,
  Coupon,
  CouponActivity,
  CouponCode
} from "@/store";
import { download, xlsx } from "@/utils";
import moment from "moment";

export default Vue.extend({
  props: {
    coupon: Object as PropType<Coupon>,
    couponActivity: Object as PropType<CouponActivity>
  },
  data() {
    return {
      CouponCodeStatus,
      dispatch,
      loading: false,
      activity: {} as CouponActivity,
      form: this.$form.createForm(this),
      list: [],
      query: {
        ordering: "-id",
        page_number: 1,
        page_size: 10
      },
      count: 0,
      visible: false,
      fields: [
        {
          title: "批量生成数量",
          dataIndex: "number",
          type: "number",
          min: 1,
          max: 100000,
          precision: 0,
          rules: [
            {
              required: true,
              message: "请输入生成数量"
            }
          ]
        }
      ]
    };
  },
  computed: {
    activityId(): number {
      return Number(this.$route.params.id);
    },
    columns() {
      return [
        {
          title: "券码",
          dataIndex: "activity_code"
        },
        {
          title: "状态",
          dataIndex: "status",
          customRender: (text: string) => (CouponCodeStatus as any)[text]
        },
        {
          title: "发送标记",
          dataIndex: "send_flag",
          customRender: (text: string) =>
            text ? (CouponCodeSendFlag as any)[text] : ""
        },
        {
          title: "领取用户ID",
          dataIndex: "user_id"
        },
        {
          title: "领取时间",
          dataIndex: "claim_at",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "操作",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          width: "140px",
          align: "center"
        }
      ];
    }
  },
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .couponsGetCodeList({
          coupon_id: this.coupon.coupon.id,
          coupon_activity_id: this.couponActivity.id,
          ...query
        })
        .then(res => {
          this.list = res.results;
          this.query = {
            ...query,
            page_number: res.page_number,
            page_size: res.page_size
          };
          this.count = res.count;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onFilterChange() {
      const filter = this.form.getFieldsValue();
      this.getList({
        ...this.query,
        ...filter,
        page_number: 1
      });
    },
    onFilterReset() {
      this.form.resetFields();
      this.onFilterChange();
    },
    onOk() {
      this.visible = false;
      this.onFilterChange();
    },
    onTableChange(page: any, filters: any, sorter: any) {
      let ordering =
        sorter.order === "ascend" ? "-" + (sorter.columnKey || "") : "";
      ordering = sorter.order === "descend" ? sorter.columnKey || "" : ordering;
      this.getList({
        ...this.query,
        page_number: page.current,
        page_size: page.pageSize,
        ordering: ordering || this.query.ordering
      });
    },
    onExport() {
      this.$message.info("券码信息导出中…");
      this.loading = true;
      let list: CouponCode[] = [];
      const getList = (pageNumber: number) =>
        dispatch
          .couponsGetCodeList({
            ...this.query,
            coupon_id: this.coupon.coupon.id,
            coupon_activity_id: this.couponActivity.id,
            ordering: "-id",
            page_number: pageNumber,
            page_size: 1000
          })
          .then(res => {
            list = [...list, ...res.results];
            if (res.count > list.length) {
              getList(pageNumber + 1);
            } else {
              const data = [
                [
                  "券码",
                  "状态",
                  "发送标记",
                  "领取用户ID",
                  "领取时间",
                  "创建时间",
                  "更新时间"
                ]
              ];
              list.map(item => {
                data.push([
                  item.activity_code,
                  (CouponCodeStatus as any)[item.status],
                  (CouponCodeSendFlag as any)[item.send_flag],
                  (item.user_id || "") + "",
                  item.claim_at
                    ? moment(item.claim_at).format("YYYY-MM-DD HH:mm:ss")
                    : "",
                  moment(item.create_time).format("YYYY-MM-DD HH:mm:ss"),
                  moment(item.update_time).format("YYYY-MM-DD HH:mm:ss")
                ]);
              });
              const file = xlsx([
                {
                  name: this.coupon.coupon.title,
                  data,
                  cols: [
                    { wch: 15 },
                    { wch: 10 },
                    { wch: 20 },
                    { wch: 10 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 }
                  ]
                }
              ]);
              this.$message.success("券码信息导出成功，正在下载…");
              download(file, this.coupon.coupon.title + ".xlsx");
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
            this.$message.error("券码信息导出失败");
          });
      getList(1);
    },
    onSend() {
      window.open(this.$paths.couponSend, "_blank");
    },
    onCancel(couponCode: CouponCode) {
      this.$confirm({
        title: "确认将此券码失效？",
        centered: true,
        onOk: () => {
          dispatch
            .couponsPatchCode({
              ...couponCode,
              status: "canceled"
            })
            .then(() => {
              this.$message.success("券码失效成功");
              this.getList(this.query);
            });
        }
      });
    },
    onFlag(couponCode: CouponCode) {
      this.$confirm({
        title: "确认将此券码标记为“已人工单独发送”？",
        centered: true,
        onOk: () => {
          dispatch
            .couponsPatchCode({
              ...couponCode,
              send_flag: "manual",
              status: undefined
            } as any)
            .then(() => {
              this.$message.success("券码标记成功");
              this.getList(this.query);
            });
        }
      });
    }
  },
  mounted() {
    this.getList(this.query);
  }
});
